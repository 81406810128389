<template>
  <div :id="container"></div>
</template>
<script setup>
useHead({
  link: [
    {
      rel: "stylesheet",
      href: "https://api.mapbox.com/mapbox-gl-js/v1.10.0/mapbox-gl.css",
    },
  ],
});
import mapboxgl from "mapbox-gl";
const { locale, t } = useI18n();
import useSARegionJson from "@/config/useSARegionJson";

const props = defineProps({
  container: String,
  year: Number,
  highlightRegionId: {
    type: String,
    default: "SA",
  },
  attributionControl: {
    type: Boolean,
    default: true,
  },
  mapType: {
    type: String,
    default: "ch4",
  },
});

const emit = defineEmits(["update:region"]);

const config = useRuntimeConfig();
const map = ref();
const { regions, startYear } = usePathwaysConfig();

const saJson = JSON.parse(JSON.stringify(useSARegionJson()));

const baseMapStyles = {
  allWhite: "mapbox://styles/ch4pathways-org/clk9becqa03aw01noeze91ru3", // nothing at all visible... will rely on geojson fully
  lightMint: "mapbox://styles/ch4pathways-org/clk7oneb202th01qvamsg3fx2", // light green land
};

const initBaseData = () => {
  saJson.features.forEach((feat, index) => {
    // add numeric id's and set default visibility to features for mapbox lookups
    if (!("id" in feat) || !Number.isInteger(feat.id)) {
      feat.id = index;
      feat.properties.visibility = true;
    }
    if (
      Object.keys(regions).includes(feat.properties.id) &&
      !("population" in feat.properties)
    ) {
      // add meta data from regions config
      feat.properties.name = regions[feat.properties.id].name;
      feat.properties.hasCH4Pledge = regions[feat.properties.id].hasCH4Pledge;
      feat.properties.population = regions[feat.properties.id].population;
    }
  });
};

// to hold main map json data object
const mapJson = ref({});

// track the year to lookup in data arrays
const yearIndex = ref(props.year - startYear);
watch(
  () => props.year,
  () => {
    yearIndex.value = props.year - startYear;
  }
);

// which key to use from the map json
const choroplethDataKey = ref("population");

const createMap = () => {
  mapboxgl.accessToken = config.public.mapboxToken;
  map.value = new mapboxgl.Map({
    container: "map",
    center: [-58.3772, -24.0622],
    style:
      props.highlightRegionId === "SA"
        ? baseMapStyles.allWhite
        : baseMapStyles.lightMint,
    zoom: 1,
    interactive: false, // Disable mouse interactions
    attributionControl: props.attributionControl,
  });

  // add south america geojson layer
  map.value.on("load", function () {
    map.value.addSource("regions", {
      type: "geojson",
      data: mapJson.value, // Replace with the path to your GeoJSON file
    });

    const fillColors =
      props.mapType === "ch4"
        ? [
            "interpolate",
            ["linear"],
            ["at", yearIndex.value, ["get", choroplethDataKey.value]],
            0,
            "#E8F7F4",
            0.033,
            "#94cdc2",
            0.066,
            "#68b8a9",
            0.1,
            "#33A390",
            0.133,
            "#288d84",
            0.166,
            "#247775",
            0.2,
            "#0f5453",
          ]
        : [
          "case",
          ["==", ["get", "hasCH4Pledge"], false],
          "#E8F7F4",
          ["case",
          ["==", ["get", "hasCH4Pledge"], true],
          "#288d84", "#E8F7F4"]
        ];

    map.value.addLayer({
      id: "regions",
      type: "fill",
      source: "regions",
      paint: {
        "fill-color": fillColors,
        "fill-opacity": [
          "case",
          ["==", ["get", "visibility"], false],
          0,
          ["case", ["boolean", ["feature-state", "hover"], false], 1, 0.5],
        ],
      },
    });

    map.value.addLayer({
      id: "regions-borders",
      type: "line",
      source: "regions",
      layout: {},
      paint: {
        "line-color": "#247775",
        "line-width": 0.5,
      },
    });

    let regionsLoaded = false;
    map.value.on("sourcedata", (e) => {
      if (
        !regionsLoaded &&
        map.value.getSource("regions") &&
        map.value.isSourceLoaded("regions")
      ) {
        regionsLoaded = true;

        if (props.highlightRegionId === "SA") {
          // fly to SA region once regions are loaded
          flyToSA();
        }
      }
    });

    // holder for region popup
    const regionPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    // track hovered region
    let hoveredPolygonId = null;

    map.value.on("mousemove", "regions", (e) => {
      // Change the cursor style as a UI indicator.
      map.value.getCanvas().style.cursor = "pointer";

      // skip french guiana GF
      if (e.features[0].properties.id !== "GF") {
        // hover fill effects
        if (e.features.length > 0) {
          if (hoveredPolygonId !== null) {
            map.value.setFeatureState(
              { source: "regions", id: hoveredPolygonId },
              { hover: false }
            );
          }
          hoveredPolygonId = e.features[0].id;
          map.value.setFeatureState(
            { source: "regions", id: hoveredPolygonId },
            { hover: true }
          );
        }

        const methanePledgeHTML = `<h2 class="ml-n2"><span class="v-chip v-theme--customTheme v-chip--density-default v-chip--size-default v-chip--variant-tonal ml-8" draggable="false"><!----><span class="v-chip__underlay"></span><!----><!----><i tag="i" class="v-icon notranslate v-theme--customTheme v-icon--size-default mr-2" aria-hidden="true"><svg class="svg-inline--fa fa-leaf" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="leaf" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="" fill="currentColor" d="M272 96c-78.6 0-145.1 51.5-167.7 122.5c33.6-17 71.5-26.5 111.7-26.5h88c8.8 0 16 7.2 16 16s-7.2 16-16 16H288 216s0 0 0 0c-16.6 0-32.7 1.9-48.2 5.4c-25.9 5.9-50 16.4-71.4 30.7c0 0 0 0 0 0C38.3 298.8 0 364.9 0 440v16c0 13.3 10.7 24 24 24s24-10.7 24-24V440c0-48.7 20.7-92.5 53.8-123.2C121.6 392.3 190.3 448 272 448l1 0c132.1-.7 239-130.9 239-291.4c0-42.6-7.5-83.1-21.1-119.6c-2.6-6.9-12.7-6.6-16.2-.1C455.9 72.1 418.7 96 376 96L272 96z"></path></svg></i> ${t(
          "explorer.methanePledge"
        )}<!----><!----></span></h2>`;

        // set popup
        // title
        let description = `<h4>${e.features[0].properties.name}</h4>`;

        // conditionally add methane pledge
        if (e.features[0].properties.hasCH4Pledge) {
          description += methanePledgeHTML;
        }

        // population
        description += `<p class='popup-data'>
                      <span class='popup-label'>
                      ${props.year} ${t("explorer.population")}:
                      </span><br/>
                      ${JSON.parse(e.features[0].properties.population)[
                        yearIndex.value
                      ].toLocaleString(locale.value)}
                      </p>`;

        if (choroplethDataKey.value === "ch4PerCapita") {
          description += `<p class='popup-data'><span class='popup-label'>
                ${t("explorer.methaneEmissions")} (${props.year}):</span><br/>
                ${JSON.parse(e.features[0].properties.ch4)[
                  yearIndex.value
                ].toLocaleString(locale.value)} ktCH4</p>
          <p class='popup-data'><span class='popup-label'>
            ${t("explorer.methaneEmissionsPerCapita")} (${
            props.year
          }):</span><br/>
            ${JSON.parse(e.features[0].properties.ch4PerCapita)[
              yearIndex.value
            ].toLocaleString(locale.value)}
            tCH4/${t("explorer.person")}</p>`;
        }

        // Populate the popup and set its coordinates
        regionPopup.setLngLat(e.lngLat).setHTML(description).addTo(map.value);
      }
    });

    map.value.on("mouseleave", "regions", () => {
      map.value.getCanvas().style.cursor = "";
      // remove popup
      regionPopup.remove();
      // remove hover fill effect
      if (hoveredPolygonId !== null) {
        map.value.setFeatureState(
          { source: "regions", id: hoveredPolygonId },
          { hover: false }
        );
      }
      hoveredPolygonId = null;
    });

    // emit region id's on clicks
    map.value.on("click", "regions", function (e) {
      const regionId = e.features[0].properties.id;
      if (regionId !== "GF") {
        emit("update:region", regionId);
      }
    });
  });
};

/**
 * udpate the map data using provided object
 * @param {Object} mapData - object containing map data. Expected in same format as scenario data
 */
const updateMapCH4Data = (mapData) => {
  mapJson.value = saJson;
  // console.log(`mapJson...`, mapJson.value);
  if (JSON.stringify(mapData) !== JSON.stringify({})) {
    // console.log(`mapdata passed...`, mapData);
    // make sure population values are setup
    initBaseData();
    // update ch4 values for all countries
    mapJson.value.features.forEach((feat) => {
      // update properties
      if (feat.properties.id !== "GF") {
        feat.properties.ch4 = mapData[feat.properties.id].total.ch4;
        feat.properties.ch4PerCapita = mapData[
          feat.properties.id
        ].total.ch4.map((val, index) => {
          return (val * 1000) / feat.properties.population[index];
        });
      }
    });
    // update choroplethDataKey so ch4 values are displayed on popups
    choroplethDataKey.value = "ch4PerCapita";
    // update the map data
    const waiting = () => {
      // make sure the map source has been loaded
      const regionsSource = map.value.getSource("regions");
      if (!regionsSource) {
        setTimeout(waiting, 100);
      } else {
        regionsSource.setData(mapJson.value);
      }
    };
    waiting();
  }
};

const highlightMapRegion = (regionId) => {
  mapJson.value.features.forEach((feat) => {
    feat.properties.visibility = feat.properties.id === regionId ? true : false;
    if (feat.properties.id === regionId) {
      // Fly to the extents of the feature collection
      // console.log(`fit to poly... `, feat);
      const bounds = getPolygonBoundingBox(feat.geometry.coordinates);
      map.value.fitBounds(bounds, {
        padding: { top: 10, bottom: 10, left: 30, right: 10 },
      });
    }
  });
  // console.log(`mapJson...`, mapJson.value);
};

const flyToSA = () => {
  // fly to SA region once regions are loaded
  const bounds = [
    [-109.4452699999999936, -55.902230000000003],
    [-34.7929200000000023, 12.5902799999999999],
  ];

  // Fly to the extents of the feature collection
  map.value.fitBounds(bounds, {
    padding: { top: 10, bottom: 30, left: 10, right: 10 },
  });
};

function getPolygonBoundingBox(arr, bounds = [[], []]) {
  for (var i = 0; i < arr.length; i++) {
    if (Array.isArray(arr[i][0][0])) {
      bounds = getPolygonBoundingBox(arr[i], bounds);
    } else {
      const polygon = arr[i];
      for (var j = 0; j < polygon.length; j++) {
        const longitude = polygon[j][0];
        const latitude = polygon[j][1];
        bounds[0][0] = bounds[0][0] < longitude ? bounds[0][0] : longitude;
        bounds[1][0] = bounds[1][0] > longitude ? bounds[1][0] : longitude;
        bounds[0][1] = bounds[0][1] < latitude ? bounds[0][1] : latitude;
        bounds[1][1] = bounds[1][1] > latitude ? bounds[1][1] : latitude;
      }
    }
  }
  return bounds;
}

defineExpose({
  updateMapCH4Data,
  highlightMapRegion,
  flyToSA,
});

onBeforeMount(() => {
  initBaseData();
});

onMounted(() => {
  createMap();
});
</script>
<style lang="scss" scoped>
:deep(.popup-data) {
  font-size: 10px;
  margin: 0px 5px 15px 5px;
  padding: 0px;
  line-height: 14px;
  height: 16px;
}
:deep(.popup-data .popup-label) {
  font-weight: bold;
}
</style>
